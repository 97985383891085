/***************************************/
/* Contest Entry                       */
/* Used in contest logout page         */
/***************************************/

/* References */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";

.ContestEntry {
    &-section {
        padding: 0 0 24px;

        .Breadcrumbs-item:first-child {
            margin: 0;
        }
    }

    &-title {
        margin: -2px 0 8px;
    }

    &-titleLink {
        color: inherit;
    }

    &-detail {
        .list-unstyled;
    }

    &-item {
        display: inline;
        margin-right: 25px;
    }

    /***************************************/
    /* Responsive Stuff */
    /***************************************/

    .is-mobile-first & {
        @media (max-width: 780px) {
            &-item {
                display: block;
                width: 100%;
                margin: 5px 0;
            }
        }
    }
}
