/* Contest Card */
@import (reference) "theme/variables.less";
@import (reference) "libs/bootstrap.3.2.grid.less";
@import (reference) "libs/mixins.less";

.contestCard {
    background-color: @septenary-color-xxxlight;
    border-radius: 4px 3px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    &-cardLink {
        display: inline-block;
        width: 100%;
        margin: 0;
        border-radius: 3px 3px 0 0;
        overflow: hidden;
        border-bottom: 1px solid @border-color-light;
    }
    &-cardImage {
        position: relative;
        height: 100%;
        left: 50%;
        margin-left: -100%;
        border: 1px solid @border-color-light;
    }
    &-nameCountry {
        .type(ui);
        display: block;
        margin: 0 15px;
        padding: 12px 0 16px 0;
        text-align: left;
        border-bottom: 1px solid @border-color-light;
    }
    &-name {
        .type(ui);
        font-weight: bold;
        color: @septenary-color-xxdark;
    }
    &-country {
        .type(ui);
        display: block;
        color: @quaternary-color-xlight;
        margin-top: 1px;
    }
    &-footerDetails {
        padding: 8px 15px;
        border-radius: 0 0 3px 3px;
        overflow: hidden;
    }
    &-counterWrapper {
        float: right;
        list-style: none;
        padding: 0;
        margin: 0;
        .likeComment-column {
            .make-xs-column(6);
        }
    }
    .counterBox {
        display: inline-block;
        padding: 0;
        &-link {
            display: inline-block;
            padding: 5px 0;
            text-decoration: none;
            color: @quaternary-color-xlight;
            .type(ui);
            [class*="flicon-"] {
                stroke: none;
                width: 14px;
                height: 14px;
                vertical-align: middle;
                margin-right: 2px;
                margin-top: -3px;
                fill: @quaternary-color-xlight;
                polygon {
                    fill: @quaternary-color-xlight;
                }
                line {
                    stroke: none;
                }
            }
            &.is-null {
                color: @septenary-color-light;
                [class*="flicon-"] {
                    fill: @quaternary-color-xxxlight;
                    polygon {
                        fill: @quaternary-color-xxxlight;
                    }
                }
            }
            &:hover {
                color: @quaternary-color-xlight;
                text-decoration: none;
                [class*="flicon-"] {
                    fill: @quaternary-color-xlight;
                    polygon {
                        fill: @quaternary-color-xlight;
                    }
                }
            }
            &:not(.is-null) {
                &.heartLink {
                    color: @senary-color-dark;
                    [class*="flicon-"] {
                        fill: @senary-color-dark;
                        polygon {
                            fill: @senary-color-dark;
                        }
                    }
                }
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

/***************************************/
/* Responsive Stuff */
/***************************************/

.is-mobile-first & {
    @media (max-width: 780px) {
        .contestCard {
            &-cardLink {
                background-color: @septenary-color-xxxlight;
            }
            &-cardImage {
                position: relative;
                height: 160%;
                left: 50%;
                margin-left: -100%;
                top: -20%;
                border: 1px solid @border-color-light;
            }
        }
        .cardList-item {
            width: 100%;
            max-width: none;
        }
    }
    @media (max-width: 480px) {
        .cardList-item {
            padding: 0;
        }
        .contestCard {
            &-cardImage {
                width: 100%;
            }
        }
    }
}
