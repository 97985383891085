/* References */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";

/* Private Vars */
@avatarBorderRadius: 3px;

/* Private Mixins */
.borderBottomStyling() {
    border-bottom: 1px solid @border-color-light;
}

/***************************************/
/* Pinky Template */
/***************************************/

.pinky-template {
    // Logout Page dependencies
    @import "flux/modules/contest-card.less";
    @import "flux/modules/comment-board.less";
    @import "flux/modules/data-list.less";
    @import "flux/modules/Breadcrumbs.less";
    @import "flux/modules/Comment.less";
    @import "flux/modules/loading-overlay.less";

    // Layout dependencies
    @import "GetStarted.less";
    @import "ContestEntry.less";

    .main-content {
        background-color: @site-bg-color;
    }

    /***************************************/
    /* Logout Contest Hero */
    /***************************************/

    .TopContest-inner,
    .publicBoard-inner,
    .GetStarted-inner,
    .ContestEntry-inner {
        .site-section-inner;
        overflow: hidden;
    }
}
