/***************************************/
/* Get Started */
/***************************************/

/* References */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";
@import (reference) "libs/bootstrap.3.2.grid.less";

.GetStarted {
    margin: 71px 0 60px;
    text-align: center;

    &-list {
        overflow: hidden;
        padding: 0 0 2px;
        list-style: none;
    }

    &-step {
        .make-xs-column(4);
        margin: 0 auto;
    }

    &-header {
        .type(heading2);
        margin-bottom: 20px;
        font-weight: bold;
        text-align: center;
    }

    &-title {
        .type(ui);
        margin: 1px 0 0;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    &-desc {
        display: block;
        position: relative;
        .type(ui);
        bottom: -2px;
        margin-bottom: 31px;
    }

    &-btn {
        min-width: 320px;
        margin: 0 auto;
        text-align: center;
    }

    &-cta {
        display: block;
        margin: 16px 0;
        .font-size(16px);
        font-weight: bold;
    }

    /***************************************/
    /* Responsive Stuff */
    /***************************************/

    .is-mobile-first & {
        @media (max-width: 780px) {
            &-step {
                width: 100%;

                img {
                    width: 100%;
                }
            }

            &-btn {
                min-width: 100%;
            }
        }
    }
}
