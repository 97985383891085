/* Data list */
@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

.dataList {
    .list-unstyled(@padding: 0);
    padding: 0;

    &-header {
        .type(heading3);
        border-bottom: 1px solid @border-color-light;
        font-weight: bold;
        margin: 0;
        padding: @spacing-small;
    }

    &-set {
        .card();
        background-color: @septenary-color-xxxlight;
        border-radius: 6px;
        margin-bottom: @spacing-small + @spacing-xxsmall;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-items {
        .list-unstyled(@padding: 0 25px);
    }

    &-item {
        border-bottom: 1px solid @septenary-color-xxlight;
        overflow: hidden;
        padding: @spacing-small + @spacing-xxsmall 0 @spacing-mid;
        text-align: left;

        &:first-child {
            padding-top: @spacing-small + @spacing-xxsmall;
        }

        &:last-child {
            border: none;
        }

        &:last-of-type {
            border: none;
            padding-bottom: @spacing-small;
        }
    }

    &-itemAuthor {
        .truncate(75%);
        .type(ui);
        bottom: -1px;
        float: left;
        margin: 0;
        position: relative;
    }

    &-itemPrice {
        float: right;
        font-weight: bold;
    }

    &-authorLink {
        display: block;
        font-weight: bold;
        margin-bottom: @spacing-xxsmall;
        width: 100%;
    }

    &-link {
        .type(ui);
        border-top: 1px solid @border-color-light;
        display: table;
        margin: 0 -@spacing-mid;
        min-width: 116%;
        padding: @spacing-small - @spacing-xxsmall @spacing-mid;
        width: 100%;
    }
}
