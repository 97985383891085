/*********************************************/
/* Comment Board Module                      */
/* Currently used in contest page            */
/*********************************************/

@import (reference) "theme/variables.less";
@import (reference) "libs/mixins.less";

/* Private Variables */
@CommentBoard-avatarSize: 56px;
@media_commentBoard-quickViewSidebar: 620px;

.commentBoard {
    border-bottom: 1px solid @border-color-light;
    list-style: none;

    &:empty {
        display: none;
    }

    &-comment {
        border-left: 2px dotted @border-color-light;
        padding: 0 @spacing-mid - @spacing-xxsmall 10px 50px;
        position: relative;
        text-align: left;

        &-highlight {
            .transition(0.1s, linear);
            background-color: @tertiary-color-xxxlight;
        }

        p {
            line-height: 1.5;
            word-break: break-word;
            word-wrap: break-word;

            &:last-of-type {
                border-bottom: 1px solid @border-color-light;
                margin: 0;
                padding-bottom: @spacing-mid;
            }
        }

        &:last-child:not(:only-child) {
            padding-bottom: 0;
        }

        .view-more-comment {
            padding-top: @spacing-small + @spacing-xxsmall;
        }

        .commentBoard-subList:empty {
            & ~ .Comment--commentBoard {
                padding-top: @spacing-mid;
            }
        }

        .commentBoard-subList {
            & ~ .Comment--commentBoard {
                padding-top: 0;
            }
        }
    }

    &-avatar {
        border-radius: @border-radius;
        height: @CommentBoard-avatarSize;
        left: -28px;
        position: absolute;
        width: @CommentBoard-avatarSize;
    }

    &-avatarContainer {
        .annotation-marker {
            background-color: @quinary-color-dark;
            border-radius: 50%;
            display: block;
            height: 12px;
            left: -2px;
            position: absolute;
            top: -2px;
            width: 12px;
        }
    }

    &-viewReplies {
        padding: @spacing-xsmall 0;
    }

    &-link {
        .type(ui);
        display: table;
        margin: 0 auto;
        padding: 0 @spacing-small + @spacing-xxsmall @spacing-small + @spacing-xxsmall;
    }

    &-subList {
        list-style: none;
        padding: @spacing-large 0 0 @spacing-mid + @spacing-xxsmall;

        &:first-child {
            border-top: 1px solid @border-color-light;
        }

        &.contest-replies {
            &:empty {
                padding: 0;
            }
        }

        .commentBoard-comment {
            padding: 0 0 @spacing-xsmall @spacing-xlarge;

            &:first-child {
                padding-bottom: @spacing-large;
            }

            &:not(:only-child) {
                padding-bottom: @spacing-large + @spacing-xsmall;
            }

            &:only-child,
            &:last-child {
                border-left: none;
            }

            &::before {
                border-top: 2px dotted @border-color-light;
                content: "";
                display: inline-block;
                height: 2px;
                left: -76px;
                position: absolute;
                top: 25px;
                width: 50px;
            }

            p {
                &:last-of-type {
                    border-bottom: 1px solid @border-color-light;
                    padding-bottom: @spacing-mid;
                }
            }
        }
    }

    &-headerItems {
        margin-bottom: @spacing-xsmall;
        overflow: hidden;
    }

    &-commentHeader {
        .truncate(53%);
        border-bottom: none;
        float: left;
        margin-right: @spacing-xxsmall;
    }

    &-userType {
        .type(tiny);
        border: 1px solid @border-color-light;
        border-radius: @border-radius;
        float: left;
        margin-bottom: @spacing-xxsmall;
        padding: 2px @spacing-xsmall;
        text-transform: uppercase;
        vertical-align: middle;
    }

    &-messageText {
        clear: both;
    }

    &-replyMessage {
        min-height: 200px;
        resize: vertical;
        width: 100%;
    }

    &-commentDetails {
        .type(detail);
        float: right;
        list-style: none;
        margin-bottom: @spacing-small;
        padding: 0;

        li {
            .type(ui);
            color: @septenary-color-light;
            display: inline-block;

            &::before {
                content: "-";
                padding: 0 @spacing-xxsmall 0 0;
            }

            &:first-child {
                &::before {
                    content: "";
                }
            }
        }

        [class*="flicon-"] {
            height: 16px;
            width: 16px;
        }
    }

    &-like,
    &-reply,
    &-delete,
    &-report {
        color: @septenary-color-light;

        &:hover {
            color: @septenary-color-dark;
            text-decoration: none;

            svg {
                stroke: @septenary-color-dark;
            }
        }

        svg {
            margin-bottom: @spacing-xxsmall;
            stroke: @septenary-color-light;
            vertical-align: middle;
        }
    }

    .commentBoard-commentDetails {
        .type(detail);

        &.commentBoard-mobile {
            display: none;
        }
    }

    /* Modifiers */
    &--viewMoreComment {
        margin-left: 15px !important;
    }
}

.commentBoard {
    &.commentBoard--contestComment,
    .commentBoard-subList,
    .commentBoard-commentDetails {
        margin: 0;
    }
}

.commentBoard--small {
    padding: 0;

    .ContestCommentBoard-container {
        .commentBoard {
            padding: 0;

            &.messages-container {
                margin: @spacing-large @spacing-small + @spacing-xxsmall;
            }
        }
    }

    .commentBoard {
        padding: 0;

        &--contestComment {
            margin: 0 0 @spacing-xxxlarge;

            &:empty {
                border-bottom: none;
            }

            .commentBoard-commentHeader {
                .type(detail);
                border-bottom: none;
                height: 12px;
                margin: 0;
                text-transform: none;
            }

            &-userType {
                color: @quaternary-color-xlight;
                font-size: 9px;
            }

            .commentBoard-subList,
            .commentBoard-commentDetails {
                margin: 0;
            }

            .commentBoard-comment {
                list-style: none;
            }

            .commentBoard-commentHeader {
                margin: 0;
            }

            .commentBoard-avatar {
                border-radius: @border-radius;
            }

            .commentBoard-replyMessage {
                width: 100%;
            }
        }
    }

    .commentBoard-list {
        margin: @spacing-small + @spacing-xxsmall;
        padding: 0;
    }

    .commentBoard-comment {
        border-bottom: 1px solid @border-color-light;
        border-left: none;
        margin-bottom: @spacing-small + @spacing-xxsmall;
        padding: 0 0 @spacing-small + @spacing-xxsmall;
        text-align: left;
    }

    .commentBoard-headerItems {
        margin-bottom: @spacing-xsmall;
        min-height: 30px;
    }

    .commentBoard-avatar {
        float: left;
        height: 30px;
        left: auto;
        margin-right: @spacing-xxsmall;
        position: relative;
        width: 30px;
    }

    .commentBoard-list {
        > .commentBoard-comment {
            border-bottom: 1px solid @border-color-light;
            border-left: none;
            margin-bottom: @spacing-small + @spacing-xxsmall;
            padding-left: 0;

            &:last-child {
                border: none;
            }
        }
    }

    .commentBoard-commentHeader {
        float: none;
        margin-bottom: 0;
        margin-right: @spacing-xxsmall;
    }

    .commentBoard-subList {
        margin-left: @spacing-small !important;
        padding: @spacing-xsmall 0 0;

        .commentBoard-comment {
            border-left: 2px dotted @border-color-light;
            margin-bottom: 0;
            padding: @spacing-xsmall 0 @spacing-xsmall @spacing-xsmall;

            &::before {
                display: none;
            }

            &:last-child {
                border-left: 2px dotted @border-color-light;
                margin: 0;
                padding-bottom: 0;
            }

            p {
                &:last-of-type {
                    border: none;
                    padding-bottom: 0;
                }
            }
        }
    }

    .commentBoard-commentDetails {
        display: none;

        &.commentBoard {
            border-bottom: 1px solid @border-color-light;
            display: block !important;
            float: none;
            margin-bottom: @spacing-small + @spacing-xxsmall;
            padding-bottom: @spacing-small + @spacing-xxsmall;
        }
    }

    .commentBoard-comment {
        p {
            &:last-of-type {
                border: none;
                padding: 0;
            }
        }

        &:last-child {
            border: none;
        }
    }

    .commentBoard-commentDetails {
        &.commentBoard-mobile {
            display: block !important;
            float: none;
            margin: @spacing-xsmall 0 0;
        }
    }
}

@media (max-width: @media_commentBoard-quickViewSidebar) {
    .commentBoard--small();
}
