/**
 * Reply comment board module
 * module is used in contest quick view modal
 */

@import (reference) "theme/variables.less";

.Comment {
    display: table;
    vertical-align: top;
    width: 100%;

    &-profile {
        display: table-cell;
        height: 56px;
        line-height: 0;
        padding-right: 10px;
        width: 56px;
    }

    &-profileImage {
        border-radius: @border-radius;
    }

    &-form {
        display: table-cell;
        position: relative;
        vertical-align: top;
        width: 100%;

        .pcb-hashtag-container,
        .pcb_error {
            margin-top: 6px;
        }
    }

    &-field {
        border: 1px solid @border-color-dark;
        border-radius: @border-radius;
        height: 75px;
        padding-left: 6px;
        padding-top: 6px;
        resize: vertical;
        width: 100%;
    }

    &--commentBoard {
        padding: @spacing-mid 0 0;
    }

    @media (max-width: 620px) {
        &-profile {
            display: none;
        }
    }
}
