/* A loading overlay to place over content while it is loaded/updated */
/* The parent element needs a position:relative for this to position correctly */

@import (reference) "libs/mixins.less";
@import (reference) "theme/variables.less";

/* Animations */
@import "mixins/animated/fadeIn.less";

.loading-overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: fade(@quaternary-color-xxdark, 50%);
    z-index: 9;

    &.is-loading {
        .animated(.4s);
        .fadeIn;
        display: block;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
        width: 50px;
        height: 50px;
        background-image: url("/static/css/images/spinner/loading-symbol.svg");

        animation: Rotate .5s infinite linear;
        -webkit-animation: Rotate .5s infinite linear;
        @-ms-keyframes Rotate {
            0% {
                -ms-transform: rotate(0deg);
            }
            100% {
                -ms-transform: rotate(360deg);
            }
        }
        @-webkit-keyframes Rotate {
            0% {
                -webkit-transform: rotate(0deg);
            }
            100% {
                -webkit-transform: rotate(360deg);
            }
        }
    }

    /* sizes */

    &.is-large:after {
        width: 70px;
        height: 70px;
        margin-top: -35px;
        margin-left: -35px;
    }
    &.is-lighter {
        background-color: fade(@septenary-color-xlight, 50%);
    }
}
